// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'

// Axios
import axios from 'axios'

axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
    getSalesPeople() {
        return new Promise((resolve, reject) => {
            // mostramos Loading
            Stores.commit(types.PENDING)

            // llamada a la API
            axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
            axios.get(`/sales_people/`)
                .then(res => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    resolve(res)
                })
                .catch(error => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    const status = error.response.status
                    if (status === 401) {
                        this.logout()
                    }
                    reject(error)
                })
        })
    },
    getProvinces() {
        return new Promise((resolve, reject) => {
            // mostramos Loading
            Stores.commit(types.PENDING)

            // llamada a la API
            axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
            axios.get(`/provinces/`)
                .then(res => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    resolve(res)
                })
                .catch(error => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    const status = error.response.status
                    if (status === 401) {
                        this.logout()
                    }
                    reject(error)
                })
        })
    },
    getChannels() {
        return new Promise((resolve, reject) => {
            // mostramos Loading
            Stores.commit(types.PENDING)

            // llamada a la API
            axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
            axios.get(`/channels/`)
                .then(res => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    resolve(res)
                })
                .catch(error => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    const status = error.response.status
                    if (status === 401) {
                        this.logout()
                    }
                    reject(error)
                })
        })
    },
    getSectors() {
        return new Promise((resolve, reject) => {
            // mostramos Loading
            Stores.commit(types.PENDING)

            // llamada a la API
            axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
            axios.get(`/sectors/`)
                .then(res => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    resolve(res)
                })
                .catch(error => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    const status = error.response.status
                    if (status === 401) {
                        this.logout()
                    }
                    reject(error)
                })
        })
    },
    getBusiness() {
        return new Promise((resolve, reject) => {
            // mostramos Loading
            Stores.commit(types.PENDING)

            // llamada a la API
            axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
            axios.get(`/business/`)
                .then(res => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    resolve(res)
                })
                .catch(error => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    const status = error.response.status
                    if (status === 401) {
                        this.logout()
                    }
                    reject(error)
                })
        })
    },
    getOrigins() {
        return new Promise((resolve, reject) => {
            // mostramos Loading
            Stores.commit(types.PENDING)

            // llamada a la API
            axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
            axios.get(`/origins/`)
                .then(res => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    resolve(res)
                })
                .catch(error => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    const status = error.response.status
                    if (status === 401) {
                        this.logout()
                    }
                    reject(error)
                })
        })
    },
    verifyToken() {
        // https://www.sergioperea.net/django-vuejs-3/
        const token = window.localStorage.getItem('_token')
        if (token) {
            const jwtDecode = require('jwt-decode')
            const decode = jwtDecode(token)
            const exp = decode.exp
            // const origIat = decode.orig_iat
            // console.log(`el token expira: ${exp}, hora actual: ${origIat}`)

            const timeOne = 86400 // 1 dia => 86400, 4h => 14400, 5 min = 300 = 5*60
            // const timeTwo = 3600 // 7 días => 604800, 1h = 3600 = 1*60*60

            const now = parseInt((Date.now() / 1000).toFixed(0))

            // console.log(`** exp: ${exp}, date: ${now}`)
            // console.log(`----------  ${exp + timeOne} <= ${now} && ${now + timeTwo} < ${origIat}`)
            // console.log('++')

            // if ((exp + timeOne) <= now && (now + timeTwo) <= origIat) {
            if ((exp - timeOne) <= now) {
                // this.dispatch('refreshToken')
                // console.log('-->hacer refresh')
                this.refreshToken()
            } else {
                // No hacer nada
                // console.log('-->token no expirado')
            }
        }

        return false
    },
    refreshToken() {
        return new Promise((resolve, reject) => {
            // mostramos Loading
            Stores.commit(types.PENDING)

            // llamada a la API
            const token = window.localStorage.getItem('_token')
            axios.post('/api-token-refresh/', {'token': token})
                .then(res => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    window.localStorage.setItem('_token', res.data.token)
                    Stores.dispatch("incrementVariation")
                    resolve(res)
                })
                .catch(error => {
                    // Ocultamos Loading
                    Stores.commit(types.NOT_PENDING)
                    // console.log('----error checkToken: ' + error)
                    this.logout()
                    reject(error)
                })
        })
    },
    retryCall(request) {
        /* const method = request.method.toLowerCase()
        const url = request.url
        const params = request.params
        // console.log(`method: ${method}, url: ${url}, params: ${params}`)

        axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
        axios[method](url, params)
        // return Vue.http[method](request.url, request.params) */
    },
    logout() {
        // console.log('logout common.js')
        const isLoggedIn = Stores.getters.isLoggedIn
        if (isLoggedIn) {
            Stores.dispatch('logout')
            window.location = '/'
        }
        return false
    }
}
