<template lang="pug">
  div
    form.cargos-item
      .row(:class="{'active': descriptions || price }")
        .col-sm-6.descrip-cn
          label
            translate Cargos adicionales
            small #[translate (254 Caracteres)]
          textarea.form-control(v-model="descriptions" :placeholder="placeholder.description")
        .col-sm-2.box-2
          .dropdown
            button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPortes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{option}}
              i(class="fa fa-angle-down" aria-hidden="true")
            ul(class="dropdown-menu" aria-labelledby="ddPortes")
              li(v-for="item in options" :class="{'disabled': option == item.name}")
                span(:value="item.id" @click="changeOption(item.id, item.name)") {{item.name}}
          br
          span
            span {{moneda}}/#[translate und].
            input.form-control(type="number" min="0" v-model="price")
        .col-sm-2.box-2.c-c
            span.descuento-cn(:class="{'show' : optionId === 1}")
              translate Descuento
              input.f_discount.form-control(type="number" min="0" v-model="productDiscount" :class="{'tw-border !tw-border-[#D95353] tw-bg-[#FFDEDE]': hasErrorForDiscount}")
              img(v-if="hasErrorForDiscount" src="@/assets/img/ico_alert.svg" class="tw-absolute tw-right-[10px] tw-top-2")
              span(class="tw-absolute tw-top-[-24px] tw-right-0 tw-text-[10px]" :class="{'tw-text-[red]': hasErrorForDiscount}")
                span #[translate Máximo] 
                span &nbsp; {{ maxDiscount }} %
            span
              translate Cantidad
              input.f_quantity.form-control(type="number" min="0" v-model="quantity")
        .col-sm-2.box-2.text-right
          button( type="button" class="btn btn-action" @click="addCargos()")
            translate Añadir
          span
            translate Total
            input.form-control(type="text" v-model="calTotal" readonly="")
    p &nbsp;
</template>
<script>
export default {
  name: 'CargosItem',
  props: ['id', 'item', 'maxDiscount'],
  data () {
    return {
      option: this.$gettext('Código 0'),
      optionId: 1,
      options: [
        {'id': 1, 'name': this.$gettext('Código 0')},
        {'id': 2413, 'name': this.$gettext('Portes')},
        {'id': 3960, 'name': this.$gettext('Recogida')}
      ],
      descriptions: '',
      price: '',
      quantity: 0,
      productDiscount: 0,
      placeholder: {
        'description': this.$gettext('Descripción')
      }
    }
  },
  computed: {
    hasErrorForDiscount () {
      if (parseInt(this.productDiscount) > parseInt(this.maxDiscount)) {
        return true
      }
      return false
    },
    calTotal () {
      //this.productDiscount = (this.productDiscount > this.maxDiscount) ? this.maxDiscount : this.productDiscount
      let price = this.price * this.quantity
      let discount = (this.productDiscount * price) / 100
      // console.log(discount)
      return price - discount
    },
    cart () {
      return this.$store.getters.cart
    },
    isTypeApp () {
      return this.$config.type
    },
    moneda () {
      return this.cart.moneda
    }
  },
  created () {
    // console.log(`descuento: `, this.maxDiscount)
  },
  methods: {
    changeOption (id, name) {
      // console.log(`----- ${v}`)
      this.productDiscount = 0
      this.option = name
      this.optionId = id
    },
    addCargos () {
      if (this.calTotal > 0) {
        let oClient = this.$store.getters.client
        const idClient = (oClient) ? oClient.id : ''
        let oLead = this.$store.getters.lead
        const idLead = (oLead) ? oLead.id : ''
        const cargo = {
          'product': this.optionId,
          'description': this.descriptions,
          'price': this.price,
          'quantity': this.quantity,
          'discount': this.productDiscount,
          'client': idClient,
          'lead': idLead,
          'billable': false
        }
        // console.log(`Añadir carros.`, cargo)
        this.descriptions = ''
        this.price = ''
        this.quantity = 0
        this.productDiscount = 0
        this.$emit('toSetCargo', cargo)
      }
    }
  },
  watch: {
    descriptions: function () {
      if (this.descriptions.length > 254) {
        this.descriptions = this.descriptions.substr(0, 254)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .cargos-item{
    position:relative;
    padding:18px 0;


    & > .row{
      @include transition();
      // @include opacity(0.4);

      &.active{
        @include opacity(1);
      }
    }
  }

  .descrip-cn{
    display: flex;
    justify-content: space-between;

    label{
      color: #1C2C40;
      font-weight: $regular;
      font-size: 14px;
      margin-right:10px;
      vertical-align: top;
      small{
        display: block;
      }
    }

    textarea{
      width:270px;
      height: 100px;
      display: inline-block;
      border: 1px solid #d5d8dc;
    }
  }

  .box-2{
    padding-left: 0;
    color: #494f58;
    text-align: right;

    &.c-c{
      padding-left: 0;
      padding-right: 0;
    }

    input{
      width: 74px;
      margin-left:8px;
      display:inline-block;

      &.f_quantity, &.f_discount{
        width: 62px;
      }

      &.f_quantity{
        margin-left: 12px;
      }
    }

    .descuento-cn{
      display:block;
      height: 38px;
      line-height: 38px;
      margin-bottom: 8px;
      opacity: 0;
      @include transition();

      &.show{
        opacity: 1;
      }
    }

    .btn{
      display:block;
      margin-right: 0;
      margin-left: auto;
      margin-bottom: 8px;
      line-height: 14px;
      padding-left: 12px;
      padding-right: 12px;
    }

    .dropdown{
      margin-bottom: 0;
    }
  }
  .dropdown{
    display:inline-block;
    margin-bottom: 8px;
    width: 122px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 38px;
      line-height: 38px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;

      i{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }

  .submit-cn{
    .btn{
      margin:14px 20px 18px 0;
    }
  }
</style>
