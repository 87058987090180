import * as types from '@/store/mutation-types'

const footer = {
    state: {
        iframeVariation: 0
    },
    actions: {
        incrementVariation: ({commit}) => {
            commit(types.INCREMENT_IFRAME_VARIATION,)
        }
    },
    mutations: {
        [types.INCREMENT_IFRAME_VARIATION](state) {
            state.iframeVariation = state.iframeVariation + 1
        },
    },
    getters: {
        variation: state => {
            return state.iframeVariation
        },
    }
}

export default footer
