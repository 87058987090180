<template lang="pug">
  .homeItemDetalle
    .container
      .row
        .col-sm-3.product-cn
          picture.img-cn
            img(:src="checkEmptyImg(product.image_url)")
          div
            h3 {{product.name}}
            .row(style="clear:both")
              .col-sm-12
                p {{entries.description}}
          
        .col-sm-3
          ul
            // li(v-if="product.supplier" class="prov-cn") #[translate Prov].: {{product.supplier.name}}
            li #[translate Código]: {{product.external_id}}
            li(v-if="product.quantity_by_packet") {{product.quantity_by_packet}} #[translate unidades por envase]
            li(v-if="product.packets_by_package") {{product.packets_by_package}} #[translate envases por paquete]
        .col-sm-2
          p.text-right {{product.price | format-money}} {{productData.currency_symbol}}
        .col-sm-1
          p.text-center {{entries.quantity}}
        .col-sm-1
          p.tw-inline-block(class="tw-min-w-[32px] tw-text-right" :class="{'tw-text-[red]': productDiscount > productMaxDiscount}") {{productDiscount}} %
          <div class="tw-inline-block w-[16px] tw-relative">
            img(v-if="productDiscount > productMaxDiscount" src="@/assets/img/ico_alert.svg" class=" tw-w-[16px] tw-h-[14px] tw-opacity-1 tw-ml-[4px]")

            <span v-if="productDiscount > productMaxDiscount"  class="tw-bg-[#ff8180] tw-shadow tw-text-[#fff] tw-rounded-[4px] tw-p-1 tw-text-[12px] tw-absolute tw-w-max tw-top-5">
              span #[translate Máximo] {{ productMaxDiscount | formatNumber(5,5) }} %
            </span>
          </div>
        .col-sm-1(v-if="user.can_validate_offers")
          p.text-right {{ entries.margin | formatNumber }} %

        div(:class="{'col-sm-1': user.can_validate_offers, 'col-sm-2': !user.can_validate_offers}")
          p.text-right {{priceTotal | format-money}} {{currency_symbol}}
      
</template>

<script>
export default {
  name: 'ValidarPedidoHomeItemDetalle',
  props: ['entries', 'billable', 'type', 'fecha', 'currency_symbol'],
  data () {
    return {
      isFacturable: false,
      imgDefault: require('@/assets/img/no_disponible.png')
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    productData () {
      // console.log(this.product)
      return this.entries.product
    },
    productDiscount () {
      if (this.entries.discount) {
        const num = parseFloat(this.entries.discount)
        return num
      }

      return 0
    },
    productMaxDiscount () {
      if (this.productData.max_discount) {
        const num = parseFloat(this.productData.max_discount)
        return num
      }
      return 0
    },
    product () {
      return this.entries.product
    },
    priceTotal () {
      // return this.entries.price * this.entries.quantity
      return this.entries.base
    }
  },
  created () {
    // console.log(`entries:`, this.entries)
  },
  methods: {
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    }
  }
}
</script>

<style lang="scss" scoped>
  .homeItemDetalle{
    padding:10px 0 14px;
    text-align: left;

    & > .container{
      position: relative;

      & > .row{
        display: flex;
        align-items: center;
      }
    }

    &:hover, &:focus{
      // @include box-shadow(all, 5px, rgba($black, 0.1) );
    }
  }

  .product-cn{
    position: initial;
    display: flex;
    align-items: center;
    padding-left: 100px;

  }

  .img-cn{
    position:absolute;
    top:0;
    bottom:0;
    left:6px;
    width: 78px;
    min-height:58px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: $white;
    @include roundAll(4);
    overflow: hidden;
    border: 1px solid #efefef;

    img{
      max-width: 100%;
      max-height: 54px;
      height:auto;
    }
  }

  h3{
    color:#1C2C40;
    font-size: 13px;
    text-transform: uppercase;
    margin:0;
    font-weight: $regular;
    // @include ellipsis();
  }

  ul{
    margin:0;
    padding:0;
    list-style: none;
  }

  li{
    font-size: 12px;
    color:#4D4F5C;
  }


</style>
