import Vue from 'vue'
import Vuex from 'vuex'

// Stores
import User from '@/store/user'
import Cart from '@/store/cart'
import Favorite from '@/store/favorite'
import Client from '@/store/client'
import Center from '@/store/center'
import Lead from '@/store/lead'
import Dashboard from '@/store/dashboard'
import DashboardClient from '@/store/dashboardClient'
import Catalogo from '@/store/catalogo'
import Footer from "@/store/footer"

Vue.use(Vuex)

const stores = new Vuex.Store({
    modules: {
        user: User,
        cart: Cart,
        favorite: Favorite,
        client: Client,
        center: Center,
        lead: Lead,
        dashboard: Dashboard,
        dashboardClient: DashboardClient,
        catalogo: Catalogo,
        footer: Footer
    }
})

export default stores
