/* App */
export const PENDING = 'PENDING' // Para cuando consultamos la api y todavía no hemos recibido respuesta.
export const NOT_PENDING = 'NOT_PENDING'

/* Auth - USER */
export const LOGIN = 'LOGIN' // Para logarse
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS' // una vez estemos logados
export const LOGOUT = 'LOGOUT' // para hacer logout
export const SET_USER = 'SET_USER'
export const UPDATE_VALIDABLE_ORDERS = 'UPDATE_VALIDABLE_ORDERS' // para actualizar el valor de los pedidos pendientes.
export const UPDATE_VALIDABLE_OFFERS = 'UPDATE_VALIDABLE_OFFERS' // para actualizar el valor de las ofertas pendientes.
export const UPDATE_USER_COUNTRY = 'UPDATE_USER_COUNTRY' // para actualizar el pais del usuario.

/* Cart */
export const CART_SET_ITEM = 'CART_SET_ITEM' // Añadir item al carrito.
export const CART_UPDATE = 'CART_UPDATE' // Actualizar carrito.
export const CART_ADD_ITEM = 'CART_ADD_ITEM' // Guardaremos el último producto añadido.
export const MODAL_OTHERS_PRODUCTS_VIEW = 'MODAL_OTHERS_PRODUCTS_VIEW' // PARA CONTROLAR SI HEMOS MOSTRADO EL MODAL DE OTROS PRODUCTOS
export const SET_RECURRENT_ORDER = 'SET_RECURRENT_ORDER' // PARA IDENTIFICAR SI ES RECURRENTE
/* Favorite */
export const FAVORITE_SET_ITEM = 'FAVORITE_SET_ITEM' // Añadir item a Favoritos.
export const FAVORITE_UPDATE = 'FAVORITE_UPDATE' // Actualizar Favoritos.
export const FAVORITE_GET_ITEMS = 'FAVORITE_GET_ITEMS' // Refrecamos / cogemos todos los favoritos.

/* Client */
export const CLIENT_SUCCESS = 'CLIENT_SUCCESS'
export const SET_CLIENT = 'SET_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const REMOVE_CLIENT = 'REMOVE_CLIENT'
export const UPDATE_CATALOGUE_OPTIONS = 'UPDATE_CATALOGUE_OPTIONS'
export const UPDATE_CLIENT_DASHBOARD_OPTIONS = 'UPDATE_DASHBOARD_OPTIONS'
export const UPDATE_CLIENT_DASHBOARD_DATE = 'UPDATE_CATALOGUE_DATE'

/* Centros */
export const CENTER_SUCCESS = 'CENTER_SUCCESS'
export const SET_CENTER = 'SET_CENTER'
export const REMOVE_CENTER = 'REMOVE_CENTER'

/* Lead */
export const LEAD_SUCCESS = 'LEAD_SUCCESS'
export const SET_LEAD = 'SET_LEAD'
export const REMOVE_LEAD = 'REMOVE_LEAD'

/* Dashboard */
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS'
export const SET_DASHBOARD = 'SET_DASHBOARD'
export const REMOVE_DASHBOARD = 'REMOVE_DASHBOARD'

/* Dashboard Client */
export const UPDATE_FILTER_CAT_LIST = 'UPDATE_FILTER_CAT_LIST'
export const UPDATE_FILTER_CAT_ITEM = 'UPDATE_FILTER_CAT_ITEM'

/* Catálogo */
export const UPDATE_CATALOGUE_MIGAS_SLUG = 'UPDATE_CATALOGUE_MIGAS_SLUG' // Actualizar slug migas.
export const UPDATE_CATALOGUE_SUPPLIER = 'UPDATE_CATALOGUE_SUPPLIER' // Actualizar proveedor.
export const UPDATE_CATALOGUE_PAGE_CURRENT = 'UPDATE_CATALOGUE_PAGE_CURRENT' // Actualizar proveedor.
export const CANCEL_AXIOS = 'CANCEL_AXIOS'
export const MODAL_INFO_SOSTE_VIEW = 'MODAL_INFO_SOSTE_VIEW'

/* ORDERS */
export const EDIT_ORDER_PENDING = 'EDIT_ORDER_PENDING'
/* OFFERS */
export const EDIT_OFFER_PENDING = 'EDIT_OFFER_PENDING'

/*FOOTER*/
export const INCREMENT_IFRAME_VARIATION = "INCREMENT_IFRAME_VARIATION"