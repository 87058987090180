<template lang="pug">
  .albaran-single
    .Loader-cn(:class="{'show': isPdfLoading}")
      span.Loader-spinner
    .box-search
      .container
        .row
          .col-sm-12 &nbsp;

    .container(v-if="messageError")
      .row
        .col-sm-12
          p &nbsp;
          p(class="alert alert-warning fade in" role="alert") {{messageError}}

    div(v-if="!messageError && number")

      .box-1
        .container
          .row
            .col-sm-9
              router-link( :to="{ name: 'albaranesList'}" ) #[translate Albaranes] > &nbsp;
              span #[translate Albarán] {{number}}
            .col-sm-3.text-right
              button.btn.btn-action(@click="getAlbaranPDF") #[translate Descargar Albarán]

      .container.table-1
        .row.table-head
          .holder-cn
            h3 #[translate Cliente]
          .holder-cn
            h3  #[translate Centro de envío]
          .holder-cn
            h3
              span(v-if="numberPedido") #[translate Pedido] nº {{numberPedido}}
              span(v-else) &nbsp;
              // span.state-cn.pull-right(:class="state") {{stateLabel}}

        .row.table-grid
          .holder-cn
              p {{oClient.name}}
              p #[translate Cod]. {{oClient.external_code}}
              p &nbsp;
          .holder-cn
              p {{center.name}}
              p {{center.address}}
              p ({{center.postal_code}}, {{center.locality}})
          .holder-cn
              p
                strong #[translate Fecha de envío]:
                span {{fecha | moment("DD-MM-YYYY")}}
              p
                strong #[translate Base]:
                span {{base | format-money}} {{moneda}}
              p &nbsp;

      .container.table-2
        .row.table-head(v-if="isTypeApp === 'pedrosa'")
          .col-sm-3 {{numItems}} #[translate productos]
          .col-sm-2 &nbsp;
          .col-sm-1.text-right #[translate Nº Pedido]
          .col-sm-2.text-right #[translate Precio Unidad]
          .col-sm-2.text-right #[translate Cantidad]
          .col-sm-1.text-center #[translate Facturar]
          .col-sm-1.text-right #[translate total]

        .row.table-head(v-else)
          .col-sm-3 {{numItems}} #[translate productos]
          .col-sm-3 &nbsp;
          .col-sm-2.text-right #[translate Precio Unidad]
          .col-sm-2.text-right #[translate Cantidad]
          .col-sm-1.text-center(v-if="isTypeApp !== 'maripol'") #[translate Facturar]

          .col-sm-2.text-right(v-if="isTypeApp === 'maripol'") #[translate Total]
          .col-sm-1.text-right(v-else) #[translate Total]

        .table-grid
          bk-item(v-for="item in aProduct" :key="item.id" :entries="item" :billable="isFacturable")

      .box-3
        .container
          .row
            .col-sm-8.obser-cn
              p(v-if="details")
                strong #[translate Observaciones]:
                small {{details}}
            .col-sm-4
              ul
                li {{numItems}} #[translate Productos]: #[small {{base | format-money}} {{moneda}}]
                // li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                // li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]

      .box-4
        .container
          .row
            //- .col-sm-8
              p Guardar Pedido como Plantilla
              form
                input.form-control(type="text" placeholder="Nombre de Plantilla")
                button(type="button" class="btn btn-green" @click="saveTemplate") Guardar Pedido como Plantilla //
            .col-sm-8.text-right.pull-right
              p
                button.btn.btn-cancel(@click="goBack()") #[translate Volver]
                button.btn.btn-action(@click="goOrder()" v-if="clientAlbaran.saleorder") #[translate Ver pedido]
                button.btn.btn-green(@click="goInvoice()" v-if="clientAlbaran.saleinvoices") #[translate Ver factura]
                button.btn.btn-action(@click="replicar()" v-if="client") #[translate Añadir al carro]
              p &nbsp;

</template>
<script>
import BkItem from '@/components/albaran/Item-single.vue'

import SrvOrder from '@/services/albaranes'

export default {
  name: 'AlbaranSingle',
  props: ['id'],
  components: { BkItem },
  data () {
    return {
      isPdfLoading: false,
      number: null,
      numberPedido: null,
      fecha: null,
      oClient: null,
      center: null,
      state: null,
      state_values: null,
      base: null,
      tax: null,
      total: null,
      moneda: null,
      details: null, // descripción
      numItems: null,
      billable_center: null, // Si es facturable el centro.
      messageError: null,
      aProduct: []
    }
  },
  created () {
    this.getAlbaran()
  },
  computed: {
    isTypeApp () {
      return this.$config.type
    },
    stateLabel () {
      let result = '-'
      if (this.state_values) {
        this.state_values.map(item => {
          if (item.code === this.state) {
            result = item.value
          }
        })
      }
      return result
    },
    isFacturable () {
      return this.billable_center
    },
    client () {
      return this.$store.getters.client
    }
  },
  methods: {
    getAlbaranPDF () {
      this.isPdfLoading = true
      SrvOrder.getAlbaranPdf(this.id)
        .then(res => {
          if (res.status === 200) {
            const blob = new Blob([res.data], {type: 'application/pdf'})
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `Albaran-${this.number}.pdf`
            link.click()
            this.isPdfLoading = false
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            this.isPdfLoading = false
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          this.isPdfLoading = false
        })
    },
    getAlbaran () {
      // console.log('id: ' + this.id)
      SrvOrder.getAlbaran(this.id)
        .then(res => {
          if (res.status === 200) {
            let data = res.data
            data.saleorder = data.saleorders[0]
            if (data.saleinvoices.length > 0) {
              data.saleinvoice = data.saleinvoices[0]
            }
            // console.log(`Pedido single: `, data)
            this.number = data.number
            this.fecha = data.delivery_note_datetime
            this.oClient = data.client
            this.center = data.center
            this.state = data.state
            this.state_values = data.state_values
            this.base = data.base
            this.tax = data.tax_total
            this.total = data.total
            this.moneda = data.currency_symbol
            this.aProduct = data.entries
            this.numItems = this.aProduct.length
            this.details = data.details
            this.billable_center = data.billable_center
            this.clientAlbaran = data
            this.numberPedido = (data.saleorder && data.saleorder.number) ? data.saleorder.number : null
            // console.log(`number `, res)
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    goBack () {
      this.$router.go(-1)
    },
    saveTemplate () {
      // TODO: funcionalidad guardar template, pendiente de API.
      // console.log('Guardar plantilla')
    },
    replicar () {
      // console.log('Replicar productos:', this.aProduct)

      // const numProduct = this.aProduct.length

      this.aProduct.map((item, index) => {
        // console.log(`${this.client.id}}`)

        let obj = {
          'client': this.client.id,
          'product': item.product.id,
          'quantity': item.quantity,
          'description': '',
          'billable': false
        }

        this.$store.dispatch('addCart', {idProduct: item.product.id, quantity: item.quantity, product: obj})

        return this.$router.push({name: 'cartList'})
      })
    },
    goOrder () {
      // console.log('Order seleccionada: ', this.order.id)
      this.$router.push({name: 'pedidoSingle', params: {id: this.clientAlbaran.saleorder.id}})
    },
    goInvoice () {
      this.$router.push({name: 'facturaSingle', params: {id: this.clientAlbaran.saleinvoice.id}})

    }
  }
}
</script>
<style lang="scss" scoped>
.box-search{
  padding: 14px 0;
  background-color: #EFEFEF;
}

.alert{
  margin-top: 40px;
}

.state-cn{
  color: $orange;
  &.S{
    color: $green;
  }
}

.box-1{
  padding: 26px 0 20px;
  font-size: 16px;
  small{
    font-size: 16px;
  }

  a{
    color: $blue-light;
  }
}

.table-1{
  .table-head{
    display: flex;
    justify-content: space-between;
    margin: 6px -15px 18px;

    .holder-cn{
      width: 32%;

      &:nth-child(3){
        h3 {
          font-weight: $medium;
        }
      }
    }

    h3{
      border-bottom: 1px solid #1C2C40;
      font-size: 14px;
      font-weight: $regular;
      padding-bottom: 6px;
    }

    p{
      margin: 0;
    }
  }

  .table-grid{
    display: flex;
    justify-content: space-between;
    margin: 6px -15px 18px;
    font-size: 14px;
    color:#4D4F5C;

    .holder-cn{
      width: 32%;
      padding: 20px 18px;
      background-color: #F1F4F7;
    }

    strong{
      min-width: 130px;
      display: inline-block;
      font-weight: $regular;
    }
  }
}

.table-2{
  .table-head{
    margin: 6px 0;
    & > *{
      padding-bottom: 6px;
      border-bottom: 1px solid #979797;
      font-size: 14px;

      &:first-child{
        font-weight: $medium;
      }
    }

    p{
      margin: 0;
    }
  }
}

.box-3{
  margin:24px 0 30px;

  .row{
    border-bottom: 1px solid #1C2C40;
    padding-bottom:16px;
  }

  ul{
    padding-left: 0;
    list-style: none;
  }

  li{
    color: #5b5d5e;
    font-size: 14px;
    margin-bottom: 14px;

    small{
      font-size: 14px;
      float: right;
    }

    &:last-child{
      font-size: 14px;

      small{
        font-size: 16px;
        font-weight: $medium;
      }
    }
  }

  .obser-cn{
    strong{
      font-weight: $regular;
      color: #494f58;
      font-size: 14px;
      vertical-align: top;
      margin-right: 10px;
    }

    small{
      display: inline-block;
      font-size: 14px;
      width:70%;
      height: 80px;
    }
  }
}

.box-4{
  p{
    font-size: 18px;
    color: #494f58;
  }

  input[type="text"]{
    max-width: 328px;
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 22px;
    font-size: 16px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .btn{
    font-size: 16px;
    font-weight: $regular;
    margin-left: 20px;
  }
}
</style>
