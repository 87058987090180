<template lang="pug">
  .listadoDesplegableItem
    .container
      .row.box-info(data-toggle="collapse" :data-target="!['B43862507'].includes(client.national_id) ? '#albaran-' + entries.id : null" :class="{'activeHover': aDelivery.length}")
        .col-sm-3.product-cn
          picture.img-cn
            img(:src="checkEmptyImg(product.image_url)")
          h3 {{product.name}}
        .col-sm-2
          ul
            // li(v-if="product.supplier" class="prov-cn") #[translate Prov].: {{product.supplier.name}}
            li(v-if="isTypeApp === 'pedrosa'") #[translate Ref.]: {{product.external_id}}
            li(v-else) #[translate Código]: {{product.external_id}}
            li(v-if="product.quantity_by_packet") {{product.quantity_by_packet}} #[translate unidades por envase]
            li(v-if="product.packets_by_package") {{product.packets_by_package}} #[translate envases por paquete]
        .col-sm-1
          p.text-right(v-if="showPrice") {{entries.price | format-money}} {{currency_symbol}}
        .col-sm-1
          p.text-right {{entries.quantity}}
        .col-sm-2
          p.fecha-cn {{entries.delivery_datetime | moment("DD-MM-YYYY")}}
        .col-sm-2.total-cn
          p.text-right(v-if="showPrice") {{entries.base | format-money}} {{currency_symbol}}
        .col-sm-1.status-cn(:class="state")
          p {{state}}
            img(:src="img.derecha" width="8" class="img-rotate" v-if="aDelivery.length && !['B43862507'].includes(client.national_id)")
    .box-albaran(:id="`albaran-${entries.id}`" class="collapse" v-if="aDelivery.length")
        bk-item(v-for="item in aDelivery" :key="item.id" :entries="item" :client="client")
</template>
<script>
import commonMixins from '@/mixins/commons.js'

import BkItem from '@/components/order/mis-pedidos/albaran-desplegable-item.vue'
export default {
  name: 'ListadoDesplegableItem',
  mixins: [ commonMixins ],
  props: ['entries', 'currency_symbol','client'],
  components: { BkItem },
  data () {
    return {
      estado: null,
      img: {
        derecha: require('@/assets/img/icos/derecha.svg')
      }
    }
  },
  computed: {
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    state () {
      let result = '-'
      if (this.entries.delivery_note_lines && this.entries.delivery_note_lines.length > 0) {
        let states = this.entries.delivery_note_lines[0].delivery_note_state_values
        states.map(item => {
          // console.log(`code: ${item.code}, state: ${this.order.state}`)
          if (item.code === this.entries.delivery_note_lines[0].delivery_note_state) {
            result = item.value
          }
        })
        if (this.entries.delivery_note_lines && this.entries.delivery_note_lines.length > 0 && result === 'Pendiente') {
          result = 'En tránsito'
        }
      } else {
        this.entries.state_values.map(item => {
          // console.log(`code: ${item.code}, state: ${this.order.state}`)
          if (item.code === this.entries.state) {
            result = item.value
          }
        })
      }
      return result
    },
    product () {
      return this.entries.product
    },
    aDelivery () {
      if (this.entries.delivery_note_lines) {
        return this.entries.delivery_note_lines
      } else {
        return 0
      }
    }
  },
  created () {
    // console.log('---', this.entries.delivery_note_lines.length)
  },
  methods: {
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    }
  }
}
</script>
<style lang="scss" scoped>
.listadoDesplegableItem{

  text-align: left;

  & > .container{
    position: relative;

    & > .row{
      display: flex;
      align-items: center;
    }
  }

  .box-info{
    padding:10px 0 14px;
    &[aria-expanded="true"]{
        background-color: rgba(#129FE9, .2);
        border:1px solid #00ADFC;
    }

    &.activeHover{
      &:hover, &:focus{
          background-color: rgba(#129FE9, .2);
          border:1px solid #00ADFC;
      }
    }

    p{
      margin: 0;
    }
  }

  .img-rotate{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    height: 16px;
    margin: auto;
    @include transition();
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  [aria-expanded="true"]{
      .img-rotate{
        -moz-transform: rotate(90deg) translate(0px);
        -o-transform: rotate(90deg) translate(0px);
        -ms-transform: rotate(90deg) translate(0px);
        -webkit-transform: rotate(90deg) translate(0px);
        transform: rotate(90deg) translate(0px);
      }
  }
}

.product-cn{
  position: initial;
  display: flex;
  align-items: center;
  padding-left: 100px;
  min-height: 70px;
}

.img-cn{
  position:absolute;
  top:10px;
  bottom:10px;
  left:6px;
  width: 78px;
  min-height:58px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: $white;
  @include roundAll(4);
  overflow: hidden;
  border: 1px solid #efefef;

  img{
    max-width: 100%;
    max-height: 54px;
    height:auto;
  }
}

h3{
  color:#1C2C40;
  font-size: 13px;
  text-transform: uppercase;
  margin:0;
  font-weight: $regular;
  // @include ellipsis();
}

ul{
  margin:0;
  padding:0;
  list-style: none;
}

li{
  font-size: 12px;
  color:#4D4F5C;
}

.total-cn{
  padding-right:30px;
}

.status-cn{
  padding-left: 0;
  color:$orange;
  &.SERVIDO, &.Servido, &.S{
    color: $green;
  }
}

.fecha-cn{
  text-align: center;
  &:empty{
    &:before{
      content: '-';
    }
  }
}

.box-albaran{
  background-color: #fff;
  padding: 18px 0;
  // display: none;

  .albaranDesplegableItem{
    &:not(:last-child){
      margin-bottom: 16px;
    }
  }
}

</style>
